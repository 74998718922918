.imgWidth {
  /* width: 50%; */
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.textareaStyle {
  width: 100%;
  color: #8898aa;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
}
.textareaStyle::placeholder {
  color: #8898aa;
}
